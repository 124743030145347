<template>
  <div class="home">
<!--    <elMenu></elMenu>-->
    <img src="../../../../public/images/IPC/liveStreaming/head.jpg" width="100%">
    <div class="pet">
      <div class="content3_box">
        <div class="content3_box_1">
          <img src="../../../../public/images/IPC/liveStreaming/1.png" width="100%" height="100%">
          <div class="content3_box_sise">敬请期待1</div>
        </div>
        <div class="content3_box_1">
          <img src="../../../../public/images/IPC/liveStreaming/1.png" width="100%" height="100%">
          <div class="content3_box_sise">敬请期待2</div>
        </div>
        <div class="content3_box_1">
          <img src="../../../../public/images/IPC/liveStreaming/1.png" width="100%" height="100%">
          <div class="content3_box_sise">敬请期待3</div>
        </div>
      </div>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../../src/components/elMenu'
import foot from '../../../../src/components/foot'
import navigation from '../../../../src/components/navigation'
export default {
  name: "liveStreaming",
  components: {
    elMenu, foot,navigation
  },
}
</script>

<style scoped>
.pet{
  height:750px;
}

.content3_box {
  padding-top: 150px;
  display: flex;
  justify-content: space-around;
}

.content3_box_1 {
  width: 20%;
  height: 400px;
  border: 1px solid #ececec;
}
.content3_box_sise {
  line-height: 100px;
  text-align: center;
  font-size: 18px;
}
</style>